.error {
  background: var(--color-error);
  padding: 12px 8px;
  margin: 20px 0;
  color: white;
  display: flex;
  align-items: center;
}

.error p {
  color: white;
  margin: 0 0 0 5px;
  font-family: 'galano-reg', sans-serif;
}