.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.modal-header h3 {
  margin: 0;
}

.modal-body {
  margin-top: 20px;
}

.modal-body-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-body-header p {
  font-family: 'playfair display', serif;
  margin: 0;
}

.modal-body-header .small {
  margin: 0 5px;
}

.modal-body h4 {
  margin: 10px 0 0 0;
  font-family: 'playfair display', serif;
  font-size: 0.95rem;
}

.modal-body h5 {
  margin: 15px 0;
  font-family: 'playfair display', serif;
  font-size: 1rem;
}

.modal-body-text {
  color: var(--color-darkGray);
  margin: 0;
  font-size: 14px;
}

.modal-form {
  width: 90%;
  margin: auto;
}

.glossary-card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.glossary-card p {
  font-size: 12px;
  color: var(--color-darkGray);
  margin-left: 5px;
  margin-bottom: 5px;
}

.glossary-card h5 {
  font-family: 'playfair display', serif;
  font-size: 1rem;
  margin-bottom: 15px;
}

#booking-info-button-group {
  width: 90%;
  margin: 20px auto 0 auto;
  min-height: 30px;
}

#booking-info-button-group button {
  width: 50%;
}

