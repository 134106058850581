.intake-item {
  display: flex;
  padding: 15px 0;
  align-items: center;
  border-bottom: 0.5px solid var(--color-backgroundGray);
}

.intake-item p {
  margin: 0;
  font-size: 14px;
  color: #444;
  font-family: 'galano-reg', sans-serif;
}

.intake-lable {
  width: 80px;
  overflow-wrap: normal;
  color: #888;
  font-family: 'galano-reg';
  font-size: 14px;
}

.intake-value {
  margin-left: 15px;
  overflow-wrap: normal;
  width: calc(100% - 80px);
}

h5.intake-title {
  margin-top: 40px;
  font-size: 1.2rem;
}