#schedule-display {
  width: 95vw;
  padding: 30px 15px;
  margin: 40px auto;
  overflow: scroll;
}

.schedule-all .schedule-display-header {
  width: 115px;
  flex-shrink: 0;
}

.schedule-header-wrapper {
  padding-left: 70px;
  flex-wrap: nowrap;
}

.schedule-display-header,
.schedule-display-mobile-header {
  font-family: 'galano-reg', sans-serif;
  text-align: center;
  color: var(--color-gray);
  text-transform: upperCase;
  font-size: 11px;
}

.schedule-display-header {
  width: calc((100% - 70px)/7);
  border-left: 0.5px dotted lightGray;
}

.header-day {
  color: var(--color-darkGray);
  font-size: 22px;
  line-height: 45px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.schedule-display-header.active,
.schedule-display-mobile-header.active {
  color: var(--color-primary);
}

.schedule-display-header.active .header-day,
.schedule-display-mobile-header.active .header-day {
  background-color: var(--color-primary);
  color: white;
}

.schedule-display-header-time {
  width: 70px;
  text-align: end;
  padding-right: 5px;
  flex-shrink: 0;
}

/* Event Overlay */
.events {
  width: calc((100% - 70px)/7);
  border-left: 0.5px dotted lightgray;
  border-top: 0.5px dotted lightgray;
  font-size: 0.9rem;
  padding-left: 5px;
  cursor: pointer;
  height: 25px;
  overflow: visible;
  font-family: 'galano-reg', sans-serif;
  padding-top: 5px;
}

.events.all {
  width: 115px;
  flex-shrink: 0;
}

.schedule-display-body .flex:last-of-type .events {
  border-bottom: 0.5px dotted lightgray;
}

.events:last-of-type {
  border-right: 0.5px dotted lightgray;
}

.events:hover {
  background: var(--color-transparentTeal);
}

.events.disabled {
  background: var(--color-backgroundGrayTransparent);
  cursor: initial;
}

.events.available {
  background: var(--color-transparentTeal);
  color: var(--color-lightGray);
  border-top: none;
}

.events.available:hover {
  background: var(--color-primary);
  color: white;
}

.events.unavailable {
  background: #e0e0e0;
  color: #909090;
}

.events.unavailable p {
  font-size: 11px;
}

.events.booked {
  background: var(--color-primary);
  color: white;
  border-top: none;
  position: relative
}

.booking-info {
  text-transform: lowercase;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.booking-info p {
  margin: 0;
  font-size: 0.85rem;
  line-height: 0.95rem;
  color: white;
}

.booking-info b {
  font-family: 'galano-reg', sans-serif;
}

.booking-info i.icon:first-of-type {
  margin-left: 5px;
}

.booking-info-services p {
  font-size: 0.7rem;
  margin-top: 2.5px;
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .events {
    width: calc(100% - 70px);
    border-right: 0.5px dotted lightgray;
  }

  .schedule-display-header {
    width: calc(100% - 70px);
    border: none;
  }

  .cal-header {
    flex-direction: column;
  }

  .schedule-display-body .flex {
    flex-wrap: nowrap;
  }
}