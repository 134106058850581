:root {
  --color-darkGray: #444;
  --color-gray: #9c969a;
  --color-lightGray: #b5b5b5;
  --color-backgroundGray: #b5b5b53d;
  --color-backgroundGrayTransparent: #ededed5c;
  --color-yellow: rgb(255, 227, 175);
  --color-transparentTeal: #abb4c545;
  --color-teal: #abb4c5;
  --font-logo: 'Playfair Display', serif;
  --color-primary: #322030;
  --color-secondary: #ca5116;
  --color-secondary-dark: #b7370c;
  --color-info: #abb4c5;
  --color-info-light: #abb4c545;
  --color-error: #ef5350;
}

@font-face {
  font-family: 'galano-semibold';
  src: url('./galano/galanogrotesquealt-semibold-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-light';
  src: url('./galano/galanogrotesquealt-light-webfont.woff2') format('woff2'),
       url('./galano/galanogrotesquealt-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-reg';
  src: url('./galano/GalanoGrotesque-Regular-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'galano-bold';
  src: url('./galano/GalanoGrotesque-Bold-webfont.woff2') format('woff2'),
       url('./galano/GalanoGrotesque-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
  font-size: 14px;
}

div {
  box-sizing: border-box;
}

a, p, h1, h2, h4, h5 {
  color: #1A1818;
}

h1 {
  font-size: 2.5rem;
  font-family: var(--font-logo);
  font-weight: 900;
  color: black;
  line-height: 1em;
  margin-bottom: 25px;
}

h2 {
  font-size: 2rem;
  letter-spacing: 0;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  margin-bottom: 20px;
}

h3 {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-weight: 500;
  margin-bottom: 20px;
}

h4 {
  font-family: 'galano-semibold', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

h5 {
  font-family: 'galano-light', sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  line-height: 1.2em;
}

a {
  text-decoration: none;
  font-size: 0.9rem;
  font-family: 'galano-light', sans-serif;
  letter-spacing: .01em;
}

a:hover {
  color: var(--color-darkGray);
}

p, li {
  font-size: 1rem;
  font-family: 'galano-light', sans-serif;
  line-height: 1.4em;
  margin-bottom: 20px;
}

label {
  font-size: .75rem;
  text-transform: uppercase;
  font-family: 'galano-reg', sans-serif;
  margin-bottom: 5px;
  display: block;
  color: #1A1818;
}

b {
  font-family: 'galano-semibold', sans-serif;
}

.small {
  font-size: 9px;
  color: var(--color-info);
}

select,
textarea,
input {
  border-radius: 0;
  -webkit-appearance: none;
  border: 1px solid #bbbbbb;
  padding: 8px 10px;
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 0 20px;
  background-color: transparent;
  outline: none;
  font-size: 12px;
  font-family: 'galano-light', sans-serif;
  color: #1a1818;
  min-height: 35px;
}

select {
  min-width: 150px;
}

button {
  min-height: 35px;
}

select {
  height: 34px;
}

.search:focus,
select:focus,
textarea:focus,
input:focus {
  border: 1px solid #c4c4c4;
  box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

textarea, input {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.item-icon {
  display: flex;
  margin-bottom: 5px;
}

.item-icon p {
  margin: 0;
  font-size: 14px;
  margin-left: 5px;
  color: var(--color-darkGray);
}

.item-icon p.link {
  color: var(--color-secondary);
  font-family: 'galano-light', sans-serif;
}

.item-icon p.link:hover {
  color: var(--color-primary);
}

.item-icon .material-icons {
  color: var(--color-gray);
  font-size: 14px;
}

.line-item {
  display: flex;
  justify-content: space-between;
  color: var(--color-darkGray);
  font-family: 'galano-light', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
}

a.underline:hover {
  background-image: linear-gradient(180deg, var(--color-yellow), var(--color-yellow));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.highlight {
  background-image: linear-gradient(180deg, rgb(255, 227, 175), rgb(255, 227, 175));
  background-repeat: no-repeat;
  background-position: 4px bottom;
  background-size: 100% 10px;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: var(--color-backgroundGrayTransparent);
}

.list-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.list-item h5.item-title {
  width: 90px;
  font-size: 13px;
  font-family: 'galano-light', sans-serif;
  margin: 0;
  color: #444;
}

.list-item p {
  width: calc(100% - 100px);
  font-size: 14px;
  margin: 0 0 0 10px;
  color: #444;
}

/* layout */

.page {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.col {
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.width-50 {
  width: 50%;
  min-width: 280px;
}

.margin-30 {
  margin: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.card {
  box-shadow: 1px 1px 6px 1px #ccc;
  -webkit-box-shadow: 1px 1px 6px 1px #ccc; 
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #bbbbbb;
  padding-right: 5px;
}

input.search-input {
  border: none;
  margin-bottom: 0;
}

input.search-input:focus {
  border: none;
  box-shadow: none;
}

.elevation-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.link {
  font-family: 'Playfair Display', serif;
  font-size: 0.95rem;
  text-decoration: underline;
  cursor: pointer;
  color: var(--color-secondary);
  margin-top: 20px;
}

.link:hover {
  color: var(--color-primary);
}

/* calendar */

.calendar .react-calendar__tile--active,
.calendar .react-calendar__tile--active:enabled:hover, 
.calendar .react-calendar__tile--active:enabled:focus {
  background: var(--color-primary);
}

.calendar .react-calendar__tile--now {
  background: var(--color-info);
  color: white !important;
}

abbr[title] {
  text-decoration: none;
}

.calendar .react-calendar__month-view__days__day--weekend {
  color: var(--color-info);
}

.calendar .react-calendar__month-view__days__day--weekend {
  color: var(--color-info);
}

.calendar .react-calendar__month-view__days__day--weekend:enabled:focus {
  background-color: var(--color-primary);
  color: white;
}

.calendar .react-calendar__tile--now:enabled:hover, 
.calendar .react-calendar__tile--now:enabled:focus {
  background: var(--color-info-light);
}

.calendar .react-calendar__month-view__days__day--weekend:enabled:hover {
  background-color: var(--color-info-light);
}

.calendar.react-calendar {
  border: none;
  -webkit-box-shadow: 1px 1px 6px 1px #ccc; 
  box-shadow: 1px 1px 6px 1px #ccc;
}

/* drawer */
/* .MuiDrawer-paperAnchorDockedRight {
  width: 400px;
} */

/* card */
.MuiCard-root {
  width: 90%;
  margin: 0 auto;
  overflow: visible !important;
}

.sub-card.MuiCard-root {
  width: 100%;
  margin-top: 20px;
}

/* app bar  */

header.MuiAppBar-colorPrimary {
  background-color: white;
  color: black;
}

button.MuiTab-root {
  min-width: 50px;
}


/* button border */

.full-width-button {
  display: flex;
  width: 95%;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  margin: 0 auto 10px auto;
  align-items: center;
}

button.button-outline {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 0;
  padding: .75rem 1.5625rem;
  -webkit-transform: translate3d(.375rem,-.375rem,0);
  transform: translate3d(.375rem,-.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: black;
  height: auto;
  margin-bottom: .375rem;
  text-align: center;
  text-decoration: none;
  outline: none;
  min-width: 9rem;
  max-width: 80vw;
  font-family: 'galano-semibold', sans-serif;
  font-size: 0.9rem;
}

button.button-outline:hover,
button.button-outline:hover::before {
  color: black;
  transform: translateZ(0);
}

button.button-outline:before {
  box-sizing: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--color-yellow);
  content: "";
  -webkit-transform: translate3d(-.375rem,.375rem,0);
  transform: translate3d(-.375rem,.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

button.button-outline:after,
.button-outline:after {
  box-sizing: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  content: "";
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

button.button-outline.yellow:before {
  background-color: rgb(255, 227, 175);
}

button.button-outline.white:before {
  background-color: white;
  border: 1px solid black;
}

button.button-outline.white:after {
  border: 1px solid black;
}

button.button-outline.mint:before {
  background-color: rgb(175, 227, 221);
}

.pac-logo,
.pac-container {  
  z-index: 9999999 !important;  
  background-color: #fff;
  position: absolute!important;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

@media only screen and (max-width: 450px) {
  .flex {
    flex-wrap: wrap;
  }

  .MuiDrawer-paperAnchorDockedRight {
    width: 100%;
  }
}